import Image from "next/image";
import PredraftLightLogo from "../../public/predraft-gold-white.svg";
import PredraftDarkLogo from "../../public/predraft-gold-blue.svg";
import React from "react";
import { useMediaQuery, useTheme } from "@mui/material";

interface Props {
  variant?: "light" | "dark";
  mobileHeight?: number;
  desktopHeight?: number;
}

const Logo: React.FC<Props> = ({ variant = "dark", mobileHeight = 45, desktopHeight = 75 }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  
  return (
    <Image
      priority
      src={variant === "light" ? PredraftLightLogo : PredraftDarkLogo}
      alt="predraft logo"
      height={isMobile ? mobileHeight : desktopHeight}
    />
  );
};

export default Logo;
