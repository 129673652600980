import {
  AppBar,
  Box,
  Grid,
  IconButton,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import logo from "../../public/predraft-gold-white.svg";
import Image from "next/image";
import { TABLET_DESKTOP_ONLY } from "../const/ScreenDisplay";
import Link from "next/link";
import React from "react";
import { Menu } from "@mui/icons-material";
import { MainAppBarSignInComponent } from "./MainAppBarSignInComponent";
import { PERSONALIZE_AI_DASHBOARD, SUGGESTIONS_HREF } from "../const/Hrefs";

interface Props {
  showSideMenu: boolean;
  onOpenMenu: () => void;
}

const MainAppBar: React.FC<Props> = ({ showSideMenu, onOpenMenu }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        zIndex: (t) => t.zIndex.drawer + 1,
        background: "primary",
      }}
    >
      <Toolbar>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: "100%" }}
          spacing={4}
        >
          <Stack direction="row" spacing={4} sx={{ alignItems: "center" }}>
            <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
              {showSideMenu && (
                <IconButton
                  aria-label="open drawer"
                  edge="start"
                  size="medium"
                  onClick={() => {
                    onOpenMenu();
                  }}
                  color="primary"
                  sx={{
                    border: isMobile ? 0 : 2,
                    borderColor: theme.palette.grey[400],
                    borderRadius: 1.1,
                    padding: [1, 1, 0.5, 0.5],
                    "&:hover": {
                      backgroundColor: theme.palette.primary.light, // Use a lighter shade of primary color on hover
                      color: "#F5F5F5", // Change icon color on hover
                      borderColor: theme.palette.primary.dark,
                    },
                  }}
                >
                  <Menu sx={{ color: "white" }} />
                </IconButton>
              )}
              <Link href="/">
                <Grid display="flex" alignItems="center">
                  <Image src={logo} alt="predraft logo" height={26} />
                </Grid>
              </Link>
            </Stack>

            <Stack direction="row" spacing={4} sx={{ ...TABLET_DESKTOP_ONLY }}>
              <Link href="/topic">
                <Typography fontFamily="Open Sans Semi Bold" variant="body2">
                  Create Article
                </Typography>
              </Link>
              <Link href={SUGGESTIONS_HREF}>
                <Typography fontFamily="Open Sans Semi Bold" variant="body2">
                  Generate Ideas
                </Typography>
              </Link>
              <Link href={PERSONALIZE_AI_DASHBOARD}>
                <Typography fontFamily="Open Sans Semi Bold" variant="body2">
                  Personalize AI
                </Typography>
              </Link>
              <Link href="/pricing">
                <Typography fontFamily="Open Sans Semi Bold" variant="body2">
                  Pricing
                </Typography>
              </Link>
            </Stack>
          </Stack>

          <Box sx={{ ...TABLET_DESKTOP_ONLY }}>
            <MainAppBarSignInComponent />
          </Box>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default MainAppBar;
