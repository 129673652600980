import words from "profane-words";
import { NodeHtmlMarkdown } from "node-html-markdown";
import { Converter } from "showdown";
import { NodeHtmlMarkdownOptions } from "node-html-markdown/dist/options";

export function convertToBoolean(bool?: string): boolean {
  if (!bool) {
    return false;
  }
  const boolString = bool.toString().toUpperCase().trim();
  return ["1", "TRUE"].includes(boolString);
}

export function stringsMatch(str1: string, str2: string): boolean {
  // Replace "s" and punctuations
  const regex = /[^\w\s]|'s|s/g;
  const cleanStr1 = str1.replace(regex, "");
  const cleanStr2 = str2.replace(regex, "");

  // Ignore case and compare the cleaned strings
  return cleanStr1.toLowerCase() === cleanStr2.toLowerCase();
}

export function throwIfProfanity(...texts: (string | undefined)[]) {
  const regex = new RegExp(`\\b(${words.join("|")})\\b`, "i");
  let profanityWord = undefined;
  const match = texts.some((text) => {
    profanityWord = profanityWord || text?.match(regex);
    return profanityWord;
  });
  if (match) {
    throw new Error(
      `Inappropriate language detected in the message. Please refrain from 
        using offensive or disrespectful language such as ${profanityWord || "what was provided"}.`
    );
  }
}

export function parseIfJSON(str: string): any {
  try {
    return JSON.parse(str);
  } catch (e) {
    return undefined;
  }
}

export function dropFalsyKeys(obj: object): object {
  return Object.fromEntries(Object.entries(obj).filter(([, v]) => v));
}

export function stringifyOnlyTruthyValues(obj: object): string {
  return JSON.stringify(dropFalsyKeys(obj));
}

export const makeHtml = (markdown: string) => {
  if (!markdown) {
    return markdown;
  }
  return new Converter({ noHeaderId: true, simplifiedAutoLink: true }).makeHtml(markdown);
};

export const makeMarkdown = (html: string) => {
  if (!html) {
    return html;
  }
  let md = NodeHtmlMarkdown.translate(html, {
    textReplace: [
      ["\\[", "["],
      ["\\]", "]"],
    ],
  } as any as Partial<NodeHtmlMarkdownOptions>);
  md = md.replaceAll("\\[", "[");
  md = md.replaceAll("\\]", "]");
  return md;
};

export function splitSentences(str: string): string[] {
  const stringWithoutNewlines = str.replace(/\n/g, "");
  return stringWithoutNewlines
    .split(/(?<=[.?!])\s+/)
    .filter((sentence) => sentence.trim().length > 0);
}

export function removeTrailingSlash(str: string): string {
  return str.replace(/\/$/, "");
}

export function errorToString(error: Error): string {
  return `${error.name}: ${error.message}\n${error.stack}`;
}
