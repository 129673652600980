import { ArticleType, IAIArticle } from "../types/models/article.types";
import { parse } from "node-html-parser";
import { ZyteGateway } from "../infra/third-party/zyte/gateway";
import { IUnsplashPhoto } from "../infra/third-party/unsplash/gateway";
import { isValidUrl } from "./UrlUtils";

export function getArticleTitle(article: IAIArticle): string | undefined {
  return article?.title || article?.topic;
}

export function getArticleCoverImageUrl(
  coverImage: IUnsplashPhoto | { not_found: Date } | string
): string | undefined {
  if (typeof coverImage === "string") {
    return coverImage;
  }
  return "urls" in coverImage ? coverImage?.urls?.regular : undefined;
}

export function findAllKeywordsInArticle(
  content: string,
  targetKeywords?: string,
  relatedKeywords?: string
): {
  foundKeywords: string[];
  missingKeywords: string[];
} {
  if (!content) {
    return { foundKeywords: [], missingKeywords: [] };
  }

  const targetKeywordsList: string[] = targetKeywords?.split(/,|\n/) || [];
  const relatedKeywordsList: string[] = relatedKeywords?.split(/,|\n/) || [];
  const keywords: string[] = targetKeywordsList.concat(relatedKeywordsList);

  keywords.sort((a, b) => a.length - b.length);

  const bagOfKeywords = new Set(["for", "the", "a", "be", "and", "of", "in", "to"]);
  const uniqueKeywords = {};

  for (const keyword of keywords) {
    const words = keyword.toLowerCase().trim().split(" ");
    const newWords = words.filter((w) => !bagOfKeywords.has(w));
    newWords.forEach((w) => bagOfKeywords.add(w));
    const dedupedKeyword = newWords.join(" ");
    uniqueKeywords[keyword] = dedupedKeyword;
  }

  const missingKeywords: string[] = [];
  const foundKeywords: string[] = [];

  const html = parse(content);
  const articleContent = html.textContent.toLowerCase();
  keywords.forEach((keyword) => {
    const dedupedKeyword = uniqueKeywords[keyword];
    if (articleContent.includes(keyword) || articleContent.includes(dedupedKeyword)) {
      foundKeywords.push(keyword);
    } else {
      missingKeywords.push(keyword);
    }
  });

  return { foundKeywords, missingKeywords };
}

export function findAllLinksInArticle(
  contentHtml: string,
  links: string[]
): {
  foundLinks: string[];
  missingLinks: string[];
} {
  const missingLinks: string[] = [];
  let foundLinks: string[] = [];

  if (contentHtml) {
    const html = parse(contentHtml);
    foundLinks = html.querySelectorAll("a").map((a) => a.getAttribute("href"));
    links.forEach((link) => {
      if (!foundLinks.includes(link)) {
        missingLinks.push(link);
      }
    });
  }

  return { foundLinks, missingLinks };
}

export function isAmazonProductLink(url): boolean {
  if (!isValidUrl(url)) {
    return false;
  }
  const amazonPattern = /https?:\/\/(www\.)?amazon\.[a-z\.]{2,6}\/(?:.*\/)?dp\/[A-Z0-9]+/i;
  return amazonPattern.test(url);
}

export async function getAmazonReviews(
  url: string
): Promise<Array<{ text: string; title: string; date: string; rating: string }>> {
  try {
    // Fetch HTML from the given URL
    const { pageTitle, pageHtmlTextOnly } = await ZyteGateway.scrapeHtmlPage(url);

    // Parse the HTML
    const root = parse(pageHtmlTextOnly);

    // Select all review elements
    const reviews = root.querySelectorAll(".review");

    // Extract review details
    return reviews.map((review) => {
      const reviewTextElement = review.querySelector(".review-text-content span");
      const reviewTitleElement = review.querySelector(".review-title span:nth-child(3)");
      const reviewDateElement = review.querySelector(".review-date");
      const reviewRatingElement = review.querySelector(".review-rating");

      return {
        text: reviewTextElement ? reviewTextElement.innerText : null,
        title: reviewTitleElement ? reviewTitleElement.innerText : null,
        date: reviewDateElement ? reviewDateElement.innerText : null,
        rating: reviewRatingElement ? reviewRatingElement.innerText : null,
      };
    });
  } catch (error) {
    console.error(error);
    return [];
  }
}

export const hasAllArticleRequiredParams = (
  article: Partial<IAIArticle>,
  type: ArticleType
): boolean => {
  if (!article) {
    return false;
  }

  const isBlogMissingParams = type === ArticleType.BLOG && !article.topic;
  const isProductReviewMissingParams =
    type === ArticleType.PRODUCT_REVIEW &&
    (!article.amazon_url || !isAmazonProductLink(article.amazon_url));
  const isGuestPostMissingParams =
    type === ArticleType.GUEST_POST &&
    (!article.topic || !article.promotional_url || !article.keywords);
  const isReviseArticleMissingParams =
    type === ArticleType.REVISE_ARTICLE && (!article.article_rewrite || !article?.topic);

  return !(
    isBlogMissingParams ||
    isProductReviewMissingParams ||
    isGuestPostMissingParams ||
    isReviseArticleMissingParams
  );
};
