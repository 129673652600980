import { IUnsplashPhoto } from "../../infra/third-party/unsplash/gateway";
import { Country, Language } from "../Country";

export interface IExternalLink {
  keyword: string;
  selected_link: string;
}

export interface IInternalLink {
  keyword?: string;
  selected_link?: string;

  /** @deprecated **/
  webflow_id?: string;
  /** @deprecated **/
  anchor_text?: any;
  /** @deprecated **/
  dist?: number;
}

export enum ArticleStatus {
  NOT_STARTED = "not_started",
  IN_PROGRESS = "in_progress",
  WEB_SEARCH_COMPLETE = "web_search_complete",
  OUTLINE_COMPLETE = "outline_complete",
  CONTENT_COMPLETE = "content_complete",
  ENHANCEMENTS_COMPLETE = "enhancements_complete",
  COMPLETED = "completed",
  FAILED = "failed",
}

export enum ArticleType {
  BLOG = "blog",
  REVISE_ARTICLE = "revise_article",
  PRODUCT_REVIEW = "product_review",
  GUEST_POST = "guest_post",
}

export interface IAIArticle {
  created_at?: string;
  updated_at?: string;
  id: string;
  user_id: string;
  topic: string;
  title: string;
  keywords: string;
  focus: string;
  tone: string;
  outline: string;
  content: string;
  faq: string;
  length: "short" | "long";
  cover_image: IUnsplashPhoto | { not_found: Date } | string;
  external_links: IExternalLink[];
  internal_links: IInternalLink[];
  web_searches: string[];
  facts_to_include: string;
  links_to_include: string[];
  related_keywords: string;
  suggested_articles: IArticleSuggestion[];
  defer_execution_id: string;
  product_id: string;
  translate: Language;
  model: "gpt-3.5" | "gpt-4";
  type: ArticleType;
  amazon_url: string;
  country: Country;
  promotional_url: string;
  article_rewrite: string;
  is_archived: boolean;
  meta_description: string;
}

export interface IArticleSuggestion {
  id?: string;
  topic: string;
  keywords?: string;
  focus?: string;
  tone?: string;
  source?: string; // not used for generation
  context?: string; // not used for generation for the source
}

export interface IDBSuggestionRow {
  created_at: string;
  updated_at: string;
  user_id: string;
  id: string;
  suggestions: Array<IArticleSuggestion>;
}
