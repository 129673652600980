import Box from "@mui/material/Box";
import React, { ReactElement, useState } from "react";
import { Container, Grid, Toolbar, Typography } from "@mui/material";
import MainAppBar from "./MainAppbar";
import { MainSideMenu } from "./side-menu/MainSideMenu";
import Link from "next/link";
import Button from "@mui/material/Button";
import Logo from "./Logo";
import DocsSideMenu from "@/components/side-menu/DocsSideMenu";

interface Props {
  children: ReactElement;
  maxWidth?: "xl" | "md" | "sm" | "xs" | "lg" | false;
  showFooter?: boolean;
  showSideMenu?: "articles" | "docs" | null;
  showMainAppBar?: boolean;
  backgroundColor?: string;
}

function Footer() {
  return (
    <Box sx={{ py: 6 }}>
      <Container maxWidth="xl">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
            textAlign: "center",
          }}
        >
          <Logo desktopHeight={26} mobileHeight={26} />

          <Grid container justifyContent="center">
            <Grid item>
              <Link href="/about" passHref>
                <Button>About</Button>
              </Link>
            </Grid>
            <Grid item>
              <Link href="/pricing" passHref>
                <Button>Pricing</Button>
              </Link>
            </Grid>
            <Grid item>
              <a
                href="https://app.getreditus.com/marketplace/predraftai"
                target="_blank"
                rel="noopener nofollow noreferrer"
              >
                <Button>Affiliates</Button>
              </a>
            </Grid>
            <Grid item>
              <Link href="/terms" passHref>
                <Button>Terms</Button>
              </Link>
            </Grid>
            <Grid item>
              <Link href="/privacy" passHref>
                <Button>Privacy</Button>
              </Link>
            </Grid>
          </Grid>

          <Typography color="text.secondary" variant="body2" mt={2}>
            © {new Date().getFullYear()} predraft.ai | © NEXT AI LLC
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}

const MainLayout: React.FC<Props> = ({
  children,
  maxWidth = "lg",
  showFooter = false,
  showSideMenu = "articles",
  showMainAppBar = true,
  backgroundColor = "rgb(246,240,225)",
}) => {
  const [open, setOpenMenu] = useState<boolean>(showSideMenu === "docs"); // mobile only

  return (
    <>
      <Box
        sx={{
          minHeight: "100%",
          height: "auto",
          display: "flex",
        }}
      >
        {showMainAppBar && (
          <MainAppBar
            showSideMenu={showSideMenu !== null}
            onOpenMenu={() => {
              setOpenMenu((prev) => !prev);
            }}
          />
        )}
        {showSideMenu === "articles" && <MainSideMenu open={open} setOpenMenu={setOpenMenu} />}
        {showSideMenu === "docs" && <DocsSideMenu open={open} setOpenMenu={setOpenMenu} />}
        <Box
          component="main"
          sx={{
            width: "100%",
            flexGrow: 1,
            backgroundColor,
          }}
        >
          {showMainAppBar && <Toolbar sx={{ py: maxWidth === false ? 0 : [5, 5, 8, 8] }} />}
          <Container
            maxWidth={maxWidth}
            disableGutters={maxWidth === false}
            sx={{
              pb: maxWidth === false ? 0 : [5, 5, 8, 8],
            }}
          >
            {children}
          </Container>
          {showFooter && <Footer />}
        </Box>
      </Box>
    </>
  );
};

export default MainLayout;
