import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { docs } from "@/docs";
import { useRouter } from "next/router";

interface Props {
  open: boolean;
  setOpenMenu: (isOpen: boolean) => void;
}

export default function DocsSideMenu({ open, setOpenMenu }: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const router = useRouter();
  const { doc_id } = router.query;
  const currentDoc = doc_id ? docs.find((doc) => doc.id === doc_id) : undefined;

  return (
    <Drawer
      anchor="left"
      variant={isMobile ? "temporary" : "permanent"}
      sx={{
        width: open ? "248px" : 0,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: open ? "248px" : 0,
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
        },
        mt: 10,
      }}
      open={!isMobile || open}
      onClose={(event, reason) => {
        if (reason === "backdropClick") {
          setOpenMenu(false);
        }
      }}
    >
      <Toolbar />
      <List>
        <ListItem>
          <ListItemText>
            <Typography fontWeight="bold" sx={{ fontSize: 18, pt: 2 }}>
              PredraftAI Documentation
            </Typography>
          </ListItemText>
        </ListItem>
        {docs.map((doc) => (
          <ListItem
            button
            key={doc.id}
            selected={doc.id === currentDoc?.id}
            onClick={() => router.push(`/docs/${doc.id}`)}
          >
            <ListItemText primary={`${doc.id === currentDoc?.id ? "> " : ""}${doc.shortTitle}`} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
}
