export enum Environment {
  DEVELOPMENT = "development",
  PREVIEW = "preview",
  PRODUCTION = "production",
}

export function getEnvironmentConfig(production: any, preview: any, dev?: any) {
  if (process.env.NEXT_PUBLIC_NETLIFY_ENV === Environment.PRODUCTION) {
    return production;
  }

  if (process.env.NEXT_PUBLIC_NETLIFY_ENV === Environment.PREVIEW) {
    return preview;
  }

  if (
    !process.env.NEXT_PUBLIC_NETLIFY_ENV ||
    process.env.NEXT_PUBLIC_NETLIFY_ENV === Environment.DEVELOPMENT
  ) {
    return dev !== undefined ? dev : preview;
  }

  console.log("getEnvironmentConfig: fallback to preview");
  return preview;
}

export const BASE_URL: string = getEnvironmentConfig(
  "https://predraft.ai",
  "https://main.predraft.ai",
  "http://localhost:3000"
);

export function isDevelopment(): boolean {
  return getEnvironmentConfig(false, false, true);
}

export function isPreview(): boolean {
  return getEnvironmentConfig(false, true, false);
}

export function isProduction(): boolean {
  return getEnvironmentConfig(true, false, false);
}
