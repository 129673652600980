import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import CoreLoadingButton from "@/components/core/CoreLoadingButton";
import { getArticleTitle } from "@/utils/ArticleUtils";
import { IAIArticle } from "@/types/models/article.types";
import { logErrorFast } from "@/utils/server/Logger";

interface Props {
  article: Partial<IAIArticle>;
  onDelete: () => Promise<void>;
  handleClose: () => void;
  open: boolean;
}

const ConfirmDeleteArticleDialog = ({ article, onDelete, handleClose, open }: Props) => {
  const [deleting, setDeleting] = useState(false);

  const handleDelete = async () => {
    setDeleting(true);
    try {
      await onDelete();
    } catch (e) {
      e.source = "ConfirmDeleteArticleDialog";
      logErrorFast("ConfirmDeleteArticleDialog", e);
      throw e;
    } finally {
      setDeleting(false);
      handleClose();
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this article titled &quot;
            {getArticleTitle(article as IAIArticle)}
            &quot;?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CoreLoadingButton text="Cancel" onClick={handleClose} variant="outlined" />
          <CoreLoadingButton
            text="Delete"
            onClick={handleDelete}
            color="error"
            loading={deleting}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmDeleteArticleDialog;
