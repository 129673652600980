export enum ApiErrorType {
  UNKNOWN = "unknown",
  MISSING_PARAMS = "missing_params",
  INVALID_METHOD = "invalid_method",
  NOT_FOUND = "not_found",
  BAD_REQUEST = "bad_request", // error code 400
  SERVICE_UNAVAILABLE = "service_unavailable",
  PAYMENT_REQUIRED = "payment_required",
  SIGN_IN_REQUIRED = "sign_in_required",
  SUBSCRIPTION_EXISTS = "subscription_exists",
  EXECUTION_IN_PROGRESS = "execution_in_progress",
  UPGRADE_TRIAL_SUBSCRIPTION = "upgrade_trial_subscription",
  ALREADY_EXISTS = "already_exists",

  ACCESS_TOKEN_NOT_FOUND = "access_token_not_found",
  NO_SITES_FOUND = "no_sites_found",
}
