import { IHookResponse } from "../../types/api/core/IHookResponse";
import { IArticleResponse } from "../../types/api/endpoints/IArticleResponse";
import { IApiError } from "../../types/api/core/IApiError";
// eslint-disable-next-line import/named
import useSWR from "swr";
import { fetcherGET, fetcherPOST } from "../../infra/api";
import { IAIArticle } from "../../types/models/article.types";
import { IArticleStatusResponse } from "../../types/api/endpoints/IArticleStatusResponse";
import { IListArticlesByProductResponse } from "@/types/api/endpoints/IListArticlesByProductResponse";
import { ISuccessApiResponse } from "../../types/api/endpoints/ISuccessApiResponse";

export const useGetArticle = (
  articleId: string,
  refreshInterval?: number
): IHookResponse<IArticleResponse, IApiError> => {
  const shouldFetch = () => {
    if (!articleId) {
      return null;
    }

    return `/api/articles/${articleId}`;
  };

  const { data, error, mutate, isValidating } = useSWR<IArticleResponse>(shouldFetch, fetcherGET, {
    refreshInterval,
  });

  if (error) {
    return { error };
  }

  return { data, mutate, isValidating };
};

export const useGetArticleStatus = (
  articleId: string,
  refreshInterval?: number
): IHookResponse<IArticleStatusResponse, IApiError> => {
  const shouldFetch = () => {
    if (!articleId) {
      return null;
    }

    return `/api/articles/${articleId}/status`;
  };

  const { data, error, mutate, isValidating } = useSWR<IArticleStatusResponse>(
    shouldFetch,
    fetcherGET,
    {
      refreshInterval,
    }
  );

  if (error) {
    return { error };
  }

  return { data, mutate, isValidating };
};

async function setArticle(userId: string, article: Partial<IAIArticle>): Promise<IArticleResponse> {
  return fetcherPOST<IArticleResponse>(`/api/users/${userId}/articles/set`, {
    article,
  });
}

async function setArticleTopic(
  userId: string,
  article: Partial<IAIArticle>
): Promise<IArticleResponse> {
  return fetcherPOST<IArticleResponse>(`/api/users/${userId}/articles/set`, {
    article: {
      id: article?.id,
      defer_execution_id: article?.defer_execution_id,
      user_id: userId,
      topic: article?.topic,
      keywords: article?.keywords,
      focus: article?.focus,
      tone: article?.tone,
      length: article?.length,
      facts_to_include: article?.facts_to_include,
      related_keywords: article?.related_keywords,
      product_id: article?.product_id,
      translate: article?.translate,
      country: article?.country,
      links_to_include: article?.links_to_include,
      model: article?.model,
      amazon_url: article?.amazon_url,
      type: article?.type,
      promotional_url: article?.promotional_url,
      article_rewrite: article?.article_rewrite,
    },
  });
}

async function updateArticle(
  articleId: string,
  article: Partial<IAIArticle>
): Promise<IArticleResponse> {
  return fetcherPOST<IArticleResponse>(`/api/articles/${articleId}/update`, {
    article,
  });
}

async function updateArticleOutline(articleId: string, outline: string): Promise<IArticleResponse> {
  return fetcherPOST<IArticleResponse>(`/api/articles/${articleId}/update/outline`, {
    outline: outline,
  });
}

async function updateArticleContent(articleId: string, content: string): Promise<IArticleResponse> {
  return fetcherPOST<IArticleResponse>(`/api/articles/${articleId}/update/content`, {
    content: content,
  });
}

async function updateArticleExternalLink(
  articleId: string,
  keyword: string,
  selectedLink: string,
  content?: string
): Promise<ISuccessApiResponse> {
  return fetcherPOST<ISuccessApiResponse>(`/api/articles/${articleId}/update/external-link`, {
    keyword,
    selected_link: selectedLink,
    content,
  });
}

async function getArticle(articleId: string): Promise<IArticleResponse> {
  return fetcherGET<IArticleResponse>(`/api/articles/${articleId}`);
}

async function listArticlesByProduct(
  userId: string,
  productId: string | null,
  page: number = 1
): Promise<IListArticlesByProductResponse> {
  const queryParams = new URLSearchParams();
  queryParams.append("user_id", userId);
  queryParams.append("page", page.toString());
  if (productId) {
    queryParams.append("product_id", productId);
  }

  return fetcherGET(`/api/articles/list?${queryParams.toString()}`);
}

export const ArticleAPI = {
  getArticle,
  setArticle,
  setArticleTopic,
  updateArticle,
  updateArticleExternalLink,
  updateArticleContent,
  updateArticleOutline,
  listArticlesByProduct,
};
