import { ApiErrorType } from "../../types/api/core/ApiErrorType";
import { APIError } from "../../types/error/APIError";
import { parseIfJSON } from "@/utils/StringUtils";

async function parseFetcherResponse<T>(response: Response): Promise<T> {
  let result;
  const contentType = response.headers.get("content-type");
  if (contentType && contentType.indexOf("application/json") !== -1) {
    result = await response.json();
  } else {
    const txt = await response.text();
    result = {
      error: txt,
    };
  }

  if (result?.error?.type === ApiErrorType.SERVICE_UNAVAILABLE) {
    return result as T;
  }

  if (result.error) {
    console.error(`API Error! ${result.error.type}: ${result.error.message}`);
    throw new APIError(result.error.message, result.error.type);
  }

  // eslint-disable-next-line consistent-return
  return result as T; // parses JSON response into native JavaScript objects
}

async function fetcherSubmit<T>(
  url: string,
  method: "PUT" | "POST" | "DELETE",
  data?: {}
): Promise<T> {
  // Default options are marked with *
  const response = await fetch(url, {
    method, // *GET, POST, PUT, DELETE, etc.
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });

  return parseFetcherResponse<T>(response);
}

export async function fetcherGET<T>(url: string): Promise<T> {
  const response = await fetch(url, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    headers: {
      "Content-Type": "application/json",
    },
  });
  return parseFetcherResponse<T>(response);
}

export async function fetcherPOST<T>(url: string, data?: {}): Promise<T> {
  return fetcherSubmit<T>(url, "POST", data);
}

export async function fetcherPUT<T>(url: string, data?: {}): Promise<T> {
  return fetcherSubmit<T>(url, "PUT", data);
}

export async function fetcherDELETE<T>(url: string, data?: {}): Promise<T> {
  return fetcherSubmit<T>(url, "DELETE", data);
}

export async function fetcherStream(
  url: string,
  method: "PUT" | "POST" | "DELETE",
  onChunk: (chunk: string, isComplete: boolean) => void,
  data?: {}
): Promise<string> {
  const response = await fetch(url, {
    method, // *GET, POST, PUT, DELETE, etc.
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });

  if (!response.ok) {
    const err = await response.json();
    console.error(err.error);
    throw new APIError(err.error?.message, err.error?.type);
  }

  const res = response.body;
  if (!res) {
    return;
  }

  const reader = res.getReader();
  const decoder = new TextDecoder();
  let done = false;

  let chunk = "";
  while (!done) {
    const { value, done: doneReading } = await reader.read();
    done = doneReading;
    const chunkValue = decoder.decode(value, { stream: true });
    chunk += chunkValue;
    onChunk(chunk, done);
  }
  return chunk;
}

export async function fetcherStreamJSON<T>(
  url: string,
  method: "PUT" | "POST" | "DELETE",
  onChunk: (combinedObj: T, isComplete: boolean) => void,
  data?: {}
) {
  const response = await fetch(url, {
    method, // *GET, POST, PUT, DELETE, etc.
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });

  if (!response.ok) {
    return response.json().then((err) => {
      console.error(err.error);
      throw new APIError(err.error?.message, err.error?.type);
    });
  }

  const res = response.body;
  if (!res) {
    return;
  }

  const reader = res.getReader();
  const decoder = new TextDecoder();
  // const reader = (await ndjsonStream(res)).getReader();
  // const reader = await res.pipeTo(ndjson.parse()).on("data");

  let done;
  let accText = "";
  let combinedObj: any = {};
  while (!done) {
    const { value, done: chunkDone } = await reader.read();
    done = chunkDone;
    const chunkValue = decoder.decode(value, { stream: true });
    accText += chunkValue;
    const lines = accText.split("|");
    const parsedLines = lines.map((l) => parseIfJSON(l) || {});
    combinedObj = parsedLines.reduce((acc, curr) => {
      Object.keys(curr).forEach((k) => (acc[k] = curr[k]));
      return acc;
    }, {});
    onChunk(combinedObj, done);
  }
  return combinedObj;
}
