import { fetcherPOST } from "../../infra/api";
import { IApiResponse } from "../../types/api/core/IApiResponse";
import { SubscriptionFrequency, SubscriptionType } from "../../types/SubscriptionType";
import { LIPixelInitiateCheckout } from "@/components/marketing/LinkedinPixel";
import { ISuccessApiResponse } from "../../types/api/endpoints/ISuccessApiResponse";

async function checkout(
  userId: string,
  subscriptionType: string,
  frequency: SubscriptionFrequency,
  successUrl: string,
  cancelUrl: string
): Promise<IApiResponse> {
  LIPixelInitiateCheckout();
  return fetcherPOST<IApiResponse>(`/api/users/${userId}/checkout`, {
    subscription_type: subscriptionType,
    frequency,
    success_url: successUrl,
    cancel_url: cancelUrl,
  });
}

async function billingPortal(userId: string, returnUrl: string): Promise<IApiResponse> {
  return fetcherPOST<IApiResponse>(`/api/users/${userId}/create-stripe-portal-session`, {
    return_url: returnUrl,
  });
}

async function upgradeTrialSubscription(userId: string): Promise<ISuccessApiResponse> {
  return fetcherPOST<ISuccessApiResponse>(`/api/users/${userId}/upgrade-trial-subscription`);
}

async function upgradeToGpt4(userId: string): Promise<ISuccessApiResponse> {
  return fetcherPOST<ISuccessApiResponse>(`/api/users/${userId}/upgrade-gpt4`);
}

async function changeSubscription(
  userId: string,
  subscriptionItems: SubscriptionType[],
  frequency: SubscriptionFrequency
): Promise<ISuccessApiResponse> {
  return fetcherPOST<ISuccessApiResponse>(`/api/users/${userId}/change-subscription`, {
    subscription_items: subscriptionItems,
    frequency,
  });
}

export const CheckoutAPI = {
  checkout,
  billingPortal,
  upgradeTrialSubscription,
  upgradeToGpt4,
  changeSubscription,
};
