import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function dateTimeString(
  date: string,
  isUtc = true,
  format: string = "MMM D | h:mma"
): string {
  const parsedDate = isUtc ? dayjs.utc(date) : dayjs(date);
  const formattedDate = parsedDate.local().format(format);
  return formattedDate;
}
