import { SubscriptionType } from "../types/SubscriptionType";

export const getArticleTopicStepHref = (articleId?: string): string => {
  if (articleId) {
    return `/topic?article_id=${articleId}`;
  }
  return "/topic";
};

export const SUGGESTIONS_HREF = "/suggestions";

export const getProductArticleSuggestionsHref = (productId: string): string =>
  `/suggestions/${productId}`;

export const getSignInHref = (queryParams: { redirect_url?: string } = {}) => {
  const urlParams = new URLSearchParams(
    Object.entries(queryParams).filter(([key, value]) => value !== undefined)
  );

  return `/signin${urlParams.toString() ? "?" + urlParams.toString() : ""}`;
};

export const getSignUpHref = (queryParams: { redirect_url?: string } = {}) => {
  const urlParams = new URLSearchParams(
    Object.entries(queryParams).filter(([key, value]) => value !== undefined)
  );

  return `/signup${urlParams.toString() ? "?" + urlParams.toString() : ""}`;
};

export const getArticleTrackHref = (articleId: string): string => {
  return `/articles/${articleId}/track`;
};

export const getArticleContentHref = (articleId: string, routeToTrack?: boolean): string => {
  return getArticleTrackHref(articleId);
};

export const getArticleOutlineHref = (articleId: string): string =>
  `/articles/${articleId}/outline`;

export const getPaymentConfirmation = (
  subscription: SubscriptionType,
  articleId?: string
): string => {
  const confirmationUrl = `/confirmation?subscription=${subscription}`;
  if (articleId) {
    return `${confirmationUrl}&article_id=${articleId}`;
  }
  return confirmationUrl;
};

export const getPersonalizeAIHref = (queryParams: { redirect_url?: string } = {}) => {
  const urlParams = new URLSearchParams(
    Object.entries(queryParams).filter(([key, value]) => value !== undefined)
  );

  return `/personalize${urlParams.toString() ? "?" + urlParams.toString() : ""}`;
};

export const PERSONALIZE_AI_DASHBOARD = "/personalize/models";

export const getPersonalizeAIEditHref = (
  productId: string,
  queryParams: { redirect_url?: string } = {}
) => {
  const urlParams = new URLSearchParams(
    Object.entries(queryParams).filter(([key, value]) => value !== undefined)
  );

  return `/personalize/${productId}${urlParams.toString() ? "?" + urlParams.toString() : ""}`;
};

export const getPersonalizeAIInternalLinks = (
  productId: string,
  queryParams: { redirect_url?: string } = {}
) => {
  const urlParams = new URLSearchParams(
    Object.entries(queryParams).filter(([key, value]) => value !== undefined)
  );

  return `/personalize/${productId}/internal-links${
    urlParams.toString() ? "?" + urlParams.toString() : ""
  }`;
};
