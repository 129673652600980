import {
  Divider,
  Drawer,
  Link,
  List,
  ListItemText,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Box from "@mui/material/Box";
import { useRouter } from "next/router";
import React, { useContext, useEffect, useState } from "react";
import DrawerMenuItem from "../DrawerMenuItem";
import CoreLoadingButton from "../core/CoreLoadingButton";
import { UserArticlesContext } from "../../context/UserArticlesContext";
import AddIcon from "@mui/icons-material/Add";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import {
  getArticleContentHref,
  getArticleTopicStepHref,
  PERSONALIZE_AI_DASHBOARD,
  SUGGESTIONS_HREF,
} from "../../const/Hrefs";
import { MOBILE_ONLY } from "../../const/ScreenDisplay";
import { MainAppBarSignInComponent } from "../MainAppBarSignInComponent";
import { dateTimeString } from "@/utils/TimeUtils";
import { ArticleStatus, IAIArticle } from "../../types/models/article.types";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { MiniArticleProgressBar } from "../ArticleProgressBar";
import { ArticleAPI, useGetArticleStatus } from "../../client/api/ArticleAPI";
import { getArticleTitle } from "../../utils/ArticleUtils";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import { useGetUserSubscription } from "../../client/api/UserAPI";
import { useAuth } from "../../utils/client/useAuth";
import ConfirmDeleteArticleDialog from "@/ConfirmDeleteArticleDialog";
import { logErrorFast } from "@/utils/server/Logger";

const ArticleStrategyDrawerMenuItems = () => {
  const router = useRouter();

  const MENU_ITEMS = [
    {
      label: "Generate Ideas",
      icon: <AutoAwesomeIcon />,
      href: SUGGESTIONS_HREF,
    },
    {
      label: "Personalize AI",
      icon: <DeviceHubIcon />,
      href: PERSONALIZE_AI_DASHBOARD,
    },
  ];

  return (
    <>
      {MENU_ITEMS.map((item) => (
        <DrawerMenuItem
          key={item.label}
          menuItem={{
            label: item.label,
            selected: router.asPath.includes(item.href),
            icon: item.icon,
            handleClick: () => router.push(item.href),
          }}
          textProps={{ variant: "body2", fontWeight: "bold" }}
        />
      ))}
    </>
  );
};

const getDrawerMenuIcon = (status: ArticleStatus) => {
  if (status === ArticleStatus.COMPLETED) {
    return <CheckCircleIcon color="success" />;
  }
  if (status === ArticleStatus.FAILED) {
    return <ErrorIcon color="error" />;
  }
  return <ArticleOutlinedIcon />;
};

const ArticleProgressIndicator: React.FC<{ status: ArticleStatus }> = ({ status }) => {
  if (!status || status === ArticleStatus.NOT_STARTED || status === ArticleStatus.COMPLETED) {
    return null;
  }

  if (status === ArticleStatus.FAILED) {
    return (
      <Typography variant="body2" color="error" sx={{ pb: 1 }}>
        An error occurred with this article. Click here to try again.
      </Typography>
    );
  }
  return (
    <Box sx={{ pb: 1 }}>
      <MiniArticleProgressBar status={status} />
    </Box>
  );
};

const ArticleListItem: React.FC<{ article: IAIArticle }> = ({ article }) => {
  const router = useRouter();
  const [refreshInterval, setRefreshInterval] = useState<number>(0);
  const { data } = useGetArticleStatus(article.id, refreshInterval);
  const status = data?.status;
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  const { deleteArticle } = useContext(UserArticlesContext);

  const handleDelete = async () => {
    try {
      await ArticleAPI.updateArticle(article.id, { is_archived: true }); // update db
      deleteArticle(article.id); // update UserArticlesContext
      setIsDeleteDialogOpen(false);
    } catch (e) {
      e.source = "ArticleListItem";
      logErrorFast("ArticleListItem", e);
      throw e;
    }
  };

  useEffect(() => {
    if (
      !status ||
      status === ArticleStatus.NOT_STARTED ||
      status === ArticleStatus.COMPLETED ||
      status === ArticleStatus.FAILED
    ) {
      setRefreshInterval(0);
    } else {
      setRefreshInterval(5000);
    }
  }, [status]);

  return (
    <>
      <DrawerMenuItem
        key={article.id}
        menuItem={{
          label: getArticleTitle(article) || article?.keywords || article?.amazon_url,
          subtitle: dateTimeString(article.created_at),
          selected: router.query.article_id === article.id,
          handleClick: () => router.push(getArticleContentHref(article.id, true)),
          handleDelete: () => setIsDeleteDialogOpen(true),
          icon: getDrawerMenuIcon(status),
        }}
        textProps={{ variant: "body2" }}
      >
        <ArticleProgressIndicator status={status} />
      </DrawerMenuItem>
      <ConfirmDeleteArticleDialog
        article={article}
        onDelete={handleDelete}
        handleClose={() => setIsDeleteDialogOpen(false)}
        open={isDeleteDialogOpen}
      />
    </>
  );
};

const ArticleListDrawerMenuItems: React.FC<{ setOpenMenu: (isOpen: boolean) => void }> = ({
  setOpenMenu,
}) => {
  const router = useRouter();
  const { articles } = useContext(UserArticlesContext);

  useEffect(() => {
    if (articles !== undefined && articles.length) {
      setOpenMenu(true);
    }
  }, [articles]);

  return (
    <Stack spacing={1}>
      <Link href={getArticleTopicStepHref(articles?.at(0)?.id)} underline="hover" color="inherit">
        <ListItemText
          primary="Articles"
          primaryTypographyProps={{
            variant: "body1",
            fontFamily: "Open Sans Semi Bold",
            align: "center",
            color: "primary",
          }}
        />
      </Link>
      <Box display="flex" justifyContent="center" sx={{ px: 2 }}>
        <CoreLoadingButton
          variant="contained"
          color="secondary"
          text="New Article"
          onClick={() => router.push(getArticleTopicStepHref())}
          startIcon={<AddIcon />}
          fullWidth={true}
          size="medium"
        />
      </Box>
      <Box>
        {articles?.map((article) => (
          <ArticleListItem key={article.id} article={article} />
        ))}
      </Box>
      <Box display="flex" justifyContent="center">
        <CoreLoadingButton
          variant="text"
          text="See All"
          onClick={() => router.push(PERSONALIZE_AI_DASHBOARD)}
        />
      </Box>
    </Stack>
  );
};

interface Props {
  open: boolean;
  setOpenMenu: (isOpen: boolean) => void;
}

export function MainSideMenu({ open, setOpenMenu }: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { userId } = useAuth();
  const { data } = useGetUserSubscription(userId);
  const hasSubscription = data?.has_subscription;

  return (
    <>
      <Drawer
        anchor="left"
        variant={isMobile ? "temporary" : "permanent"}
        sx={{
          width: open ? "248px" : 0,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: open ? "248px" : 0,
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          },
          mt: 10,
        }}
        open={!isMobile || open}
        onClose={(event, reason) => {
          if (reason === "backdropClick") {
            setOpenMenu(false);
          }
        }}
      >
        <Box sx={{ maxHeight: "100vh", overflow: "auto" }}>
          <Toolbar sx={{ mb: 1 }} />
          <List>
            <Stack spacing={1}>
              <Stack spacing={1} sx={{ ...MOBILE_ONLY }}>
                <ArticleStrategyDrawerMenuItems />
                <Divider />
              </Stack>
              <ArticleListDrawerMenuItems setOpenMenu={setOpenMenu} />
            </Stack>
          </List>
          <Stack alignItems="center" sx={{ ...MOBILE_ONLY, pt: 2, pb: 5 }} spacing={2}>
            <Divider sx={{ width: "100%" }} />
            <MainAppBarSignInComponent />
          </Stack>
        </Box>
        <Box
          sx={{
            p: 2,
            backgroundColor: theme.palette.secondary.main,
            display: hasSubscription ? "block" : "none",
          }}
        >
          <Typography
            textAlign="center"
            variant="body1"
            sx={{
              fontFamily: "Open Sans Bold",
              mb: 1,
            }}
          >
            Join the Predraft AI Affiliate Program
          </Typography>
          <Typography variant="body2">
            Earn <strong>30% commission for life</strong> on referred customer payments to Predraft
            AI.
          </Typography>
          <Box
            display="flex"
            justifyContent="center"
            sx={{ mt: 1.5, mx: "auto", width: "fit-content" }}
          >
            <a
              href="https://app.getreditus.com/marketplace/predraftai"
              target="_blank"
              rel="noopener nofollow noreferrer"
            >
              <CoreLoadingButton text="Start Earning" onClick={() => {}} size="medium" />
            </a>
          </Box>
        </Box>
      </Drawer>
    </>
  );
}
