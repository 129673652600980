import { fetcherGET, fetcherPOST } from "../../infra/api";
import useSWR from "swr";
import { IHookResponse } from "../../types/api/core/IHookResponse";
import { IApiError } from "../../types/api/core/IApiError";
import { ISuccessApiResponse } from "../../types/api/endpoints/ISuccessApiResponse";
import { IUserAllArticlesResponse } from "../../types/api/endpoints/IUserAllArticlesResponse";
import { IUserSubscriptionResponse } from "@/types/api/endpoints/IUserSubscriptionResponse";

export const useGetUserSubscription = (
  userId: string
): IHookResponse<IUserSubscriptionResponse, IApiError> => {
  const shouldFetch = () => {
    if (!userId) {
      return null;
    }

    return `/api/users/${userId}/subscription`;
  };
  const { data, error, mutate, isValidating } = useSWR<IUserSubscriptionResponse>(
    shouldFetch,
    fetcherGET
  );
  if (data?.error || error) {
    return { error: data?.error || error };
  }

  return { data, mutate, isValidating };
};

export const useGetUserArticles = (
  userId: string
): IHookResponse<IUserAllArticlesResponse, IApiError> => {
  const shouldFetch = () => {
    if (!userId) {
      return null;
    }

    return `/api/users/${userId}/articles`;
  };

  const { data, error, mutate, isValidating } = useSWR<IUserAllArticlesResponse>(
    shouldFetch,
    fetcherGET
  );

  if (data?.error || error) {
    return { error: data?.error || error };
  }

  return { data, mutate, isValidating };
};

async function initializeUser(userId: string): Promise<ISuccessApiResponse> {
  // get fbclid from fbclid query param if it exists
  const params = new URLSearchParams(window.location.search);
  const fbclid = params.get("fbclid");
  const gclid = params.get("gclid");
  return fetcherPOST<ISuccessApiResponse>(`/api/users/${userId}/initialize`, {
    fbclid,
    gclid,
  });
}

async function logParams(userId: string, params: { [key: string]: string }) {
  await fetcherPOST("/api/auth/log-params", {
    user_id: userId,
    params,
  });
}

export default {
  initializeUser,
  logParams,
};
