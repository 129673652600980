import { ApiErrorType } from "../api/core/ApiErrorType";

export class APIError extends Error {
  type: ApiErrorType;

  constructor(message: string, type: ApiErrorType) {
    super(message);
    this.type = type;
    Object.setPrototypeOf(this, APIError.prototype);
  }
}
