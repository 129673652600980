export enum LoggingCategory {
  OPEN_AI = "open_ai",
  DB_MIGRATE = "db_migrate",
  ZYTE = "zyte",
  SUPABASE = "supabase",
}

type LoggingProps = {
  message: string;
  reference?: string;
  data?: object;
  category?: LoggingCategory;
};

type LogErrorProps = LoggingProps & {
  error?: Error;
  suppressFullError?: boolean; // we don't always need the full error logged
};

function writeLog(logType: "ok" | "info" | "warning" | "error", props: LogErrorProps) {
  let infoData: any = {
    ...props.data,
    message: props.message,
  };
  if (props.category) {
    infoData = {
      ...infoData,
      category: props.category,
    };
  }
  if (props.reference) {
    infoData = {
      ...infoData,
      reference: props.reference,
    };
  }
  if (props.error) {
    infoData = {
      fullError: props.suppressFullError ? "suppressed" : props.error,
      errorStack: props.error.stack,
      ...infoData,
    };
  }

  switch (logType) {
    case "info":
      console.info(props.message, infoData);
      break;
    case "warning":
      console.warn(props.message, infoData);
      break;
    case "error":
      console.error(props.message, infoData);
      break;
    default:
      console.log(props.message, infoData);
  }
}

export function logWarning(props: LogErrorProps) {
  return writeLog("warning", props);
}

export function logInfo(props: LoggingProps) {
  return writeLog("info", props);
}

export function logError(props: LogErrorProps) {
  return writeLog("error", props);
}

export function logErrorFast(src = "", error = undefined) {
  return writeLog("error", { message: src, error });
}
