/* eslint-disable max-len */
export interface Doc {
  id: string;
  shortTitle: string;
  contentMd: string;
}

// Assuming we have a `docs` object where the key is the doc_id and the value is the markdown contentMd
export const docs: Doc[] = [
  {
    id: "connect-wordpress-org",
    shortTitle: "Connect Self-Hosted Wordpress",
    contentMd: `### Connecting a Self-Hosted Wordpress Site to PredraftAI
    
**Plan required**: Expert Plan [(check your plan here)](/pricing)

To simplify your workflow, you can push articles from PredraftAI to your self-hosted Wordpress site. 
This guide will walk you through a simple set up process to set up the connection and use this feature.

#### What you need
To connect a self hosted Wordpress site you need 3 things:
1. **The url of the website**. Ensure to use the base url, which is the main landing page address of your site, not urls for specific articles or subpages. For example, opt for "https://example.com" over "https://example.com/blog" or "https://example.com/wp-login".
2. **Your Wordpress username**. Enter the username you use for logging into your Wordpress account. This is the username that will be displayed as the author and will have permissions to publish drafts imported from PredraftAI. Avoid using application password names or any other identifiers.
3. **App password**. For security, we recommend you generate an app password on Wordpress (instructions below). If you cannot do this, you can use your own Wordpress sign in password.

**Tip**: Make sure you use the right username and password for the site you want to add. If you don’t see your site, you probably used the wrong account. 

#### Generating an app password

**Step 1**: Go to the Wordpress admin portal for the site you want to add (usually something like “https://mysite.com/wp-admin”)

**Step 2**: Click Users > Profile > Scroll to the bottom of the page

<a href="/img-docs/connect-wordpress-org/img1.png"><img src="/img-docs/connect-wordpress-org/img1.png" alt="Wordpress Profile Page" width="500"/></a>

**Step 3**: Find the box "New application password name". Type in PredraftAI and click "Add new application password". 

<a href="/img-docs/connect-wordpress-org/img2.png"><img src="/img-docs/connect-wordpress-org/img2.png" alt="Wordpress App Password Creation" width="500"/></a>

**Step 4**: Copy the password it shows! Make sure to save it because you won't be able to see it again (if you lose it, generate a new one with a new name, any name). 


#### Setup your info on PredraftAI

**Step 1**: Click into a finished article to go to the article content page

**Step 2**: Click “Publish to Wordpress”

**Step 3**: Click "Connect to wordpress.org"

<a href="/img-docs/connect-wordpress-org/img4.png"><img src="/img-docs/connect-wordpress-org/img4.png" alt="Connect Wordpress to PredraftAI" width="500"/></a>

**Step 4**: Enter the 3 pieces of information from above and click "Connect Wordpress Site". 

<a href="/img-docs/connect-wordpress-org/img3.png"><img src="/img-docs/connect-wordpress-org/img3.png" alt="Enter Wordpress Credentials" width="500"/></a>

To do the first publish, go back to the article and click publish again. This time you’ll see your site listed. Click publish and your post will be sent as a draft and you’ll see it on your Wordpress account. 

#### Debugging Common Problems

##### **Not able to submit my 3 pieces of information**
Possible problems:
* Your information is wrong. Check for typos and copy paste the app password from scratch
* Your site is down. Your site must be online for us to talk to it. 
* You don’t have permission on Wordpress. Contact your site admin to give you permission.  

##### **It’s asking me to upgrade**
Yes, the self-hosted Wordpress integration is available on the Expert Plan and above only.  If you just want to use Wordpress.com you can get the Pro Plan 


##### **I connected the wrong account**
You can connect multiple accounts, so follow the same steps to connect another one. Deletion is not currently available (coming soon) but having multiple accounts will not interfere with each other. 

##### **I have a feature request** 
Great! Email us at hello@predraft.ai. No promises but we’re actively building new features quickly and want to get yours on the list. 
    `,
  },
];
