import { useSession } from "next-auth/react";
import { useEffect, useState } from "react";
import Cookie from "js-cookie";
import UserAPI from "../../client/api/UserAPI";
import Clarity from "./Clarity";
import { v4 as uuidv4 } from "uuid";

export const useAuth = () => {
  const { data: session, status: sessionStatus } = useSession();
  const [userId, setUserId] = useState<string>();

  const initialize = async () => {
    if (!sessionStatus || sessionStatus === "loading") {
      return;
    }
    if (sessionStatus === "authenticated" && session?.user?.id && session.user?.id !== userId) {
      // use session to override userid
      setUserId(session.user?.id);
      Cookie.set("uid", session.user.id);
      return;
    }

    // at this point, session doesn't have a userid

    // cleanup localstorage if exists
    const localUid = localStorage.getItem("uid");
    if (localUid) {
      // set cookie as local storage uid
      Cookie.set("uid", localUid);
      // remove local storage
      localStorage.removeItem("uid");
    }

    // try to use cookie as userid
    const cookieUserId = Cookie.get("uid");
    if (cookieUserId) {
      setUserId(cookieUserId);
      return;
    }

    // last resort, generate new userid
    const generatedUserId = uuidv4();
    setUserId(generatedUserId);
    Cookie.set("uid", generatedUserId);
    await UserAPI.initializeUser(generatedUserId);
    if (userId) {
      Clarity.setUserId(userId);
    }
  };

  useEffect(() => {
    initialize();
  }, [session]);

  return {
    session,
    userId,
    isSignedIn: !!session,
  };
};
