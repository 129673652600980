import "@/styles/globals.css";
import "../../public/font/stylesheet.css";
import type { AppProps } from "next/app";
import { theme } from "../styles/theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { NextPage } from "next";
import { ReactElement, ReactNode } from "react";
import MainLayout from "../components/MainLayout";
import Head from "next/head";
import AnalyticsTracking from "@/components/AnalyticsTracking";
import { SessionProvider } from "next-auth/react";
import { UserArticlesProvider } from "../context/UserArticlesContext";
import { ErrorBoundary as HighlightErrorBoundary, HighlightInit } from "@highlight-run/next/client";

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export default function MyApp({
  Component,
  pageProps: { session, ...pageProps },
}: AppPropsWithLayout) {
  // Use the layout defined at the page level, if available.
  const getLayout = Component.getLayout || ((page) => <MainLayout>{page}</MainLayout>);

  return (
    <>
      <Head>
        <title>predraft.ai | SEO Researcher & Writer | Free Forever</title>
      </Head>
      <HighlightInit
        excludedHostnames={["localhost", "staging", "main"]}
        projectId={process.env.NEXT_PUBLIC_HIGHLIGHT_PROJECT_ID}
        environment={process.env.NEXT_PUBLIC_VERCEL_ENV}
        serviceName="predraft-frontend"
        tracingOrigins
        networkRecording={{
          enabled: true,
          recordHeadersAndBody: true,
          urlBlocklist: [],
        }}
        privacySetting="none"
        disableSessionRecording
      />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <HighlightErrorBoundary>
          <SessionProvider session={session}>
            <AnalyticsTracking />
            <UserArticlesProvider>{getLayout(<Component {...pageProps} />)}</UserArticlesProvider>
          </SessionProvider>
        </HighlightErrorBoundary>
      </ThemeProvider>
    </>
  );
}
