// import amplitude from "amplitude-js";
// import { webAttributionPlugin } from "@amplitude/plugin-web-attribution-browser";
import { pageViewTrackingPlugin } from "@amplitude/plugin-page-view-tracking-browser";
import { isProduction } from "../../../utils/Environment";
import * as amplitude from "@amplitude/analytics-browser";

const AMPLITUDE_API_KEY = "2cc28f20a0d88d68cf85c122849b0254";

let amplitudeInstance;

function init() {
  if (typeof window !== "undefined") {
    amplitude.init(AMPLITUDE_API_KEY, undefined, {
      serverUrl: "https://predraft.ai/proxy/alpha",
    });

    amplitude.add(
      pageViewTrackingPlugin({
        trackOn: () => window?.location !== undefined,
        trackHistoryChanges: "all",
      })
    );
    amplitudeInstance = amplitude;
  }
}

init();

function wrapper(innerF) {
  return (...args) => {
    console.log("isProduction", isProduction());
    if (isProduction()) {
      if (!amplitudeInstance) {
        init();
      }
      return innerF(...args);
    }
    console.log("tracked Amplitude call on", innerF.name, args);
    return undefined;
  };
}

const context = { app: process.env.NEXT_PUBLIC_DOMAIN };

const actions = {
  track: wrapper((event, props: { [key: string]: any }) => {
    console.log("about to logEvent", event, props, context);
    amplitudeInstance.logEvent(event, {
      ...props,
      ...context,
    });
  }),
  identify: wrapper((id: string) => {
    amplitudeInstance.setUserId(id);
  }),
  reset: wrapper(() => {
    // @ts-ignore
    amplitudeInstance.reset();
    // TODO @mayank does this work?
  }),
  setUserProperties: wrapper((props: { [key: string]: string }) => {
    console.log("props", props);
    const identify = new amplitude.Identify();
    for (const key in props) {
      console.log(key, props[key]);
      identify.set(key, props[key]);
    }
    console.log("identify", identify);
    amplitudeInstance.identify(identify);
    // amplitudeInstance.setUserProperties(props);
  }),
};

export const AmplitudeBrowser = actions;
