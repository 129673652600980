export function LIPixelGenerateArticle() {
  return (
    <img
      height="1"
      width="1"
      style={{ display: "none" }}
      alt=""
      src="https://px.ads.linkedin.com/collect/?pid=5265490&conversionId=13958594&fmt=gif"
    />
  );
}

export function LIPixelInitiateCheckout() {
  return fetch("https://px.ads.linkedin.com/collect/?pid=5265490&conversionId=13958602&fmt=gif");
}

// export function LIPixelSignup() {
//   return (
//     <img
//       height="1"
//       width="1"
//       style={{ display: "none" }}
//       alt=""
//       src="https://px.ads.linkedin.com/collect/?pid=5265490&conversionId=13958610&fmt=gif"
//     />
//   );
// }

export function LIStartTrial() {
  return (
    <img
      height="1"
      width="1"
      style={{ display: "none" }}
      alt=""
      src="https://px.ads.linkedin.com/collect/?pid=5265490&conversionId=13958618&fmt=gif"
    />
  );
}

export function LIViewTrackPage() {
  return (
    <img
      height="1"
      width="1"
      style={{ display: "none" }}
      alt=""
      src="https://px.ads.linkedin.com/collect/?pid=5265490&conversionId=15530113&fmt=gif"
    />
  );
}
