import Button from "@mui/material/Button";
import { CircularProgress } from "@mui/material";
import React, { ReactNode } from "react";

interface Props {
  text: any;
  onClick: (e: any) => any | undefined;
  disabled?: boolean;
  fullWidth?: boolean;
  loading?: boolean;
  size?: "small" | "medium" | "large";
  color?: "primary" | "secondary" | "inherit" | "error";
  type?: "submit" | "button";
  variant?: "text" | "outlined" | "contained";
  sx?: {};
  name?: string;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  forwardRef?: React.Ref<HTMLButtonElement> | undefined;
}

const CoreLoadingButton: React.FC<Props> = ({
  text,
  onClick = undefined,
  disabled = false,
  loading = false,
  fullWidth = false,
  color = "primary",
  size = "large",
  type = "button",
  variant = "contained",
  sx = {},
  name = undefined,
  startIcon = undefined,
  endIcon = undefined,
  forwardRef = undefined,
}) => {
  return (
    <Button
      ref={forwardRef}
      disableElevation
      disabled={disabled || loading || false}
      size={size || "large"}
      onClick={onClick}
      fullWidth={fullWidth || false}
      color={color || "primary"}
      type={type}
      variant={variant || "contained"}
      name={name}
      startIcon={startIcon}
      endIcon={endIcon}
      sx={{
        "&.MuiButton-outlined": {
          backgroundColor: "white",
          "&:hover": { backgroundColor: "white" },
        },
        borderRadius: 1,
        ...sx,
      }}
    >
      {loading ? <CircularProgress size={24} /> : text}
    </Button>
  );
};

export default CoreLoadingButton;
