import { signOut, useSession } from "next-auth/react";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useRouter } from "next/router";
import { BASE_URL } from "../utils/Environment";
import { CheckoutAPI } from "../client/api/CheckoutAPI";
import { getPersonalizeAIHref, getSignInHref, getSignUpHref } from "../const/Hrefs";
import Logout from "@mui/icons-material/Logout";
import { MOBILE_ONLY, TABLET_DESKTOP_ONLY } from "../const/ScreenDisplay";
import CoreLoadingButton from "./core/CoreLoadingButton";
import Cookie from "js-cookie";

export const MainAppBarSignInComponent = () => {
  const theme = useTheme();
  const displaySessionName = !useMediaQuery(theme.breakpoints.down("lg"));
  const { data: session } = useSession();
  const router = useRouter();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignIn = async () => {
    await router.push(getSignInHref());
  };

  const handleSignUp = async () => {
    await router.push(getSignUpHref({ redirect_url: getPersonalizeAIHref() }));
  };

  const handleSignOut = async () => {
    await signOut({
      callbackUrl: BASE_URL,
    });
    await Cookie.remove("uid");
  };

  const handleAccount = async () => {
    if (!session?.user?.id) {
      await router.push(getSignInHref());
      return;
    }
    const returnUrl = window.location.href;

    const { redirect_url: redirectUrl } = await CheckoutAPI.billingPortal(
      session.user.id,
      returnUrl
    );

    if (redirectUrl) {
      await router.push(redirectUrl);
    } else {
      await router.push("/pricing");
    }
  };

  const name = session?.user?.name || session?.user?.email;

  return (
    <>
      <Stack direction="row" sx={{ ...MOBILE_ONLY, alignItems: "center" }} spacing={2}>
        {session ? (
          <>
            <CoreLoadingButton
              text="Sign Out"
              onClick={handleSignOut}
              size="medium"
              variant="text"
            />
            <CoreLoadingButton text="Account" color="secondary" onClick={handleAccount} />
          </>
        ) : (
          <>
            <CoreLoadingButton text="Sign In" onClick={handleSignIn} size="medium" variant="text" />
            <CoreLoadingButton
              text="Start Writing"
              onClick={handleSignUp}
              color="secondary"
              size="medium"
            />
          </>
        )}
      </Stack>

      <Stack direction="row" sx={{ ...TABLET_DESKTOP_ONLY, alignItems: "center" }} spacing={1}>
        {session ? (
          <>
            {displaySessionName && (
              <Typography fontFamily="Open Sans Semi Bold" variant="body1">
                Hello, {name}
              </Typography>
            )}

            <Box sx={{ ...TABLET_DESKTOP_ONLY }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <Avatar
                  sx={{
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.primary.main,
                    width: 36,
                    height: 36,
                  }}
                >
                  <Typography variant="body1" fontFamily="Open Sans Bold">
                    {name?.charAt(0)}
                  </Typography>
                </Avatar>
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={Boolean(anchorEl)}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    minWidth: "160px",
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    "& .MuiAvatar-root": {
                      width: 30,
                      height: 30,
                      ml: -0.5,
                      mr: 1,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem onClick={handleAccount}>
                  <ListItemIcon>
                    <Avatar />
                  </ListItemIcon>
                  <Typography>Account</Typography>
                </MenuItem>
                <Divider sx={{ my: 2 }} />
                <MenuItem onClick={handleSignOut}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  <Typography>Sign Out</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </>
        ) : (
          <Stack direction="row" spacing={2} alignItems="center">
            <CoreLoadingButton
              text="Sign In"
              onClick={handleSignIn}
              size="medium"
              variant="text"
              sx={{ color: "white" }}
            />
            <CoreLoadingButton
              text="Start Writing"
              onClick={handleSignUp}
              color="secondary"
              size="medium"
            />
          </Stack>
        )}
      </Stack>
    </>
  );
};
