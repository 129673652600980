import React, { ReactNode, useState } from "react";
import {
  Grid,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
// eslint-disable-next-line import/named
import { TypographyProps } from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";

export interface MenuItem {
  label: string;
  subtitle?: string;
  selected: boolean;
  handleClick: () => any;
  handleDelete?: () => any;
  icon?: ReactNode;
  disabled?: boolean;
}

interface Props {
  menuItem: MenuItem;
  textProps?: TypographyProps<any>;
  children?: ReactNode;
}

const DrawerMenuItem: React.FC<Props> = ({
  menuItem,
  textProps = undefined,
  children = undefined,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <ListItem
      key={menuItem.label}
      disablePadding
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <ListItemButton
        selected={menuItem.selected}
        disabled={menuItem.disabled}
        onClick={menuItem.handleClick}
        sx={{
          "&.Mui-selected": {
            borderRadius: 1,
          },
          position: "relative", // For absolute positioning of the trash icon
          ...(isHovered && {
            backgroundColor: "rgba(255, 255, 255, 0.2)", // Whitening on hover
          }),
        }}
      >
        <Grid container width="100%">
          <Grid item xs={12}>
            <Stack direction="row" alignItems="center">
              {menuItem.icon && (
                <ListItemIcon sx={{ minWidth: "0px", mr: 2 }}>{menuItem.icon}</ListItemIcon>
              )}
              <Stack>
                <ListItemText
                  primary={menuItem.label}
                  primaryTypographyProps={textProps as any}
                  secondary={menuItem.subtitle}
                />
                {children}
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        {menuItem.handleDelete && isHovered && (
          <IconButton
            sx={{
              position: "absolute",
              right: 8,
              top: "50%",
              transform: "translateY(-50%)",
              backgroundColor: "background.paper", // Solid background color
              padding: "4px", // Adjust padding to control the size of the solid background
              "&:hover": {
                color: "primary.main", // Style on hover
                backgroundColor: "background.default", // Optional, change background on hover
              },
            }}
            onClick={(e) => {
              e.stopPropagation();
              menuItem.handleDelete();
            }}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </ListItemButton>
    </ListItem>
  );
};

export default DrawerMenuItem;
