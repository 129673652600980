import { NextRouter, useRouter } from "next/router";
import { useEffect } from "react";
import { useAuth } from "../utils/client/useAuth";
import UserAPI from "@/client/api/UserAPI";
import { ReditusGateway } from "@/infra/third-party/reditus/client-gateway";

let AmplitudeBrowser: any;

export const IMPORTANT_LOGGING_PARAMS = new Set([
  "gbraid",
  "wbraid",
  "gclid",
  "fbclid",
  "adid",
  "adgroupid",
  "adposition",
  "campaignid",
  "keyword",
  "referrer_source",
]);

function getFbclidFromCookie() {
  try {
    const cookies = document.cookie
      ?.split(";")
      .map((cookie) => cookie.split("="))
      .reduce(
        (accumulator, [key, value]) => ({
          ...accumulator,
          [key.trim()]: decodeURIComponent(value),
        }),
        {}
      );
    // @ts-ignore
    const fbclid = cookies?._fbc;
    return fbclid;
  } catch (e) {
    return undefined;
  }
}

const cache: { [key: string]: any } = {};

async function logImportantQueryParams(router: NextRouter, userId: string) {
  const params = router.query;

  const userProperties: { [key: string]: any } = {
    user_id: userId,
    app: "predraft",
  };

  Object.entries(params).forEach(([key, value]) => {
    if (cache[key]) {
      return;
    }

    if (IMPORTANT_LOGGING_PARAMS.has(key)) {
      userProperties[key] = value;
      cache[key] = true;
    }
  });
  AmplitudeBrowser.setUserProperties(userProperties);
  if (!userProperties["fbclid"]) {
    userProperties["fbclid"] = getFbclidFromCookie();
  }
  userProperties["reditus_id"] = ReditusGateway.getIdFromCookie();
  console.log("about to log params with reditus id", userProperties["reditus_id"]);
  await UserAPI.logParams(userId, userProperties);
}

export default function AnalyticsTracking() {
  const router = useRouter();
  const { userId } = useAuth();

  useEffect(() => {
    if (router?.query && userId) {
      logImportantQueryParams(router, userId);
    }
  }, [router?.query, userId]);

  useEffect(() => {
    if (global.window) {
      AmplitudeBrowser = require("@/infra/third-party/amplitude").AmplitudeBrowser;
    }
  }, []);
  return null;
}
