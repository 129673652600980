import { createTheme, responsiveFontSizes } from "@mui/material";

export const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: "#022B44",
      },
      secondary: {
        main: "#ffb300",
      },
    },
    typography: {
      fontFamily: "Open Sans",
    },
    components: {
      // Name of the component
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
            fontWeight: 600,
            fontFamily: "Open Sans Bold",
          },
          containedPrimary: {
            color: "white",
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            fontFamily: "Open Sans Semi Bold",
            fontSize: "14px",
          },
        },
      },
    },
  })
);
