function setUserId(userId: string, sessionId?: string) {
  try {
    // @ts-ignore
    window?.clarity && window.clarity("identify", userId);
  } catch (e) {
    console.error("window.clarity error", e);
  }
}

function setTag(tag: string) {
  try {
    // @ts-ignore
    window?.clarity && window.clarity("identify", userId);
  } catch (e) {
    console.error("window.clarity error", e);
  }
}

export default {
  setUserId,
  setTag,
};
