import { getCookie } from "../../../utils/client/cookie";

// Reditus script parses pk, uid from query params and sets _gr_id cookie
// may not happen in time for this function to get the cookie
function getIdFromCookie(): string | undefined {
  const cookie = getCookie("_gr_id");
  console.log("reditus id", cookie);
  return cookie;
}

function logSignup(email: string) {
  if (!email) {
    return;
  }
  const cookie = getCookie("_gr_id");
  if (!cookie) {
    return;
  }
  try {
    console.log("reditus attempt");
    // @ts-ignore
    gr("track", "conversion", { email });
    // TODO @mayank cleanup console logs after prod verified
    console.log("reditus success");
  } catch (e) {
    console.dir(e);
  }
}

export const ReditusGateway = {
  getIdFromCookie,
  logSignup,
};
