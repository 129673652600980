import { Box, keyframes, LinearProgress, Stack, styled, Typography, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import DoneIcon from "@mui/icons-material/Done";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { ArticleStatus } from "../types/models/article.types";

const seamlessGradient = `
  linear-gradient(45deg, #66CCFF 0%, #66FF99 50%, #66CCFF 100%)
`;

const gradientAnimation = keyframes`
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: 0% 0;
  }
`;

const AnimatedProgressBar = styled(motion.div)(({ theme }) => ({
  height: "100%",
  borderRadius: "15px",
  background: seamlessGradient,
  backgroundSize: "200% 100%",
  animation: `${gradientAnimation} 4s linear infinite`,
}));

const ProgressBarContainer = styled(Box)(({ theme }) => ({
  height: "30px", // Increased height from 20px to 30px
  width: "100%",
  borderRadius: "15px", // Increased the borderRadius to maintain a proportionate curve
  backgroundColor: theme.palette.grey[200],
  border: "1px solid #e0e0e0",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
  overflow: "hidden",
}));

enum ProgressBarStep {
  LEARNING_TOPIC = "learning_topic",
  RESEARCHING_ONLINE = "researching_online",
  DRAFTING_OUTLINE = "drafting_outline",
  WRITING_ARTICLE = "writing_article",
  FINISHING_TOUCHES = "finishing_touches",
  COMPLETED = "completed",
}

const ARTICLE_PROGRESS_BAR_STEPS: ProgressBarStep[] = [
  ProgressBarStep.LEARNING_TOPIC,
  ProgressBarStep.RESEARCHING_ONLINE,
  ProgressBarStep.DRAFTING_OUTLINE,
  ProgressBarStep.WRITING_ARTICLE,
  ProgressBarStep.FINISHING_TOUCHES,
  ProgressBarStep.COMPLETED,
];

const getArticleStatusToProgressBarStep = (status: ArticleStatus): ProgressBarStep => {
  switch (status) {
    case ArticleStatus.NOT_STARTED:
    case ArticleStatus.IN_PROGRESS:
      return ProgressBarStep.RESEARCHING_ONLINE;
    case ArticleStatus.WEB_SEARCH_COMPLETE:
      return ProgressBarStep.DRAFTING_OUTLINE;
    case ArticleStatus.OUTLINE_COMPLETE:
      return ProgressBarStep.WRITING_ARTICLE;
    case ArticleStatus.CONTENT_COMPLETE:
      return ProgressBarStep.FINISHING_TOUCHES;
    case ArticleStatus.ENHANCEMENTS_COMPLETE:
    case ArticleStatus.COMPLETED:
      return ProgressBarStep.COMPLETED;
    default:
      return ProgressBarStep.LEARNING_TOPIC;
  }
};

export const toFriendlyProgressBarStep = (step: ProgressBarStep): string => {
  switch (step) {
    case ProgressBarStep.LEARNING_TOPIC:
      return "Learning Topic";
    case ProgressBarStep.RESEARCHING_ONLINE:
      return "Researching the Web";
    case ProgressBarStep.DRAFTING_OUTLINE:
      return "Drafting Outline";
    case ProgressBarStep.WRITING_ARTICLE:
      return "Writing Article";
    case ProgressBarStep.FINISHING_TOUCHES:
      return "Finishing Touches";
    case ProgressBarStep.COMPLETED:
      return "Completed";
    default:
      return step;
  }
};

function getProgressBarPercentage(currentStep: ProgressBarStep): number {
  const stepIndex = ARTICLE_PROGRESS_BAR_STEPS.indexOf(currentStep);
  return ((stepIndex + 1) / ARTICLE_PROGRESS_BAR_STEPS.length) * 100;
}

function getCompletedSteps(currentStep: ProgressBarStep): ProgressBarStep[] {
  const stepIndex = ARTICLE_PROGRESS_BAR_STEPS.indexOf(currentStep);
  return ARTICLE_PROGRESS_BAR_STEPS.slice(0, stepIndex);
}

export const MiniArticleProgressBar: React.FC<{ status: ArticleStatus }> = ({ status }) => {
  const currentStep: ProgressBarStep = getArticleStatusToProgressBarStep(status);
  return (
    <LinearProgress
      variant="determinate"
      value={getProgressBarPercentage(currentStep)}
      color="success"
    />
  );
};

export const ArticleProgressBar: React.FC<{ status: ArticleStatus }> = ({ status }) => {
  const theme = useTheme();
  const progressControls = useAnimation();
  const currentStep: ProgressBarStep = getArticleStatusToProgressBarStep(status);
  const isInProgressStep = (step: ProgressBarStep): boolean => step === currentStep;
  const isCompletedStep = (step: ProgressBarStep): boolean =>
    getCompletedSteps(currentStep).includes(step);

  useEffect(() => {
    const progressPercentage = getProgressBarPercentage(currentStep);
    progressControls.start({
      width: `${progressPercentage}%`,
      transition: { duration: 0.5, ease: "easeOut" },
    });
  }, [currentStep, progressControls]);

  return (
    <Box width="100%">
      <ProgressBarContainer>
        <AnimatedProgressBar
          initial={{
            width: `${getProgressBarPercentage(currentStep)}%`,
          }}
          animate={progressControls}
        />
      </ProgressBarContainer>
      <Stack direction={["column", "column", "row", "row"]} justifyContent="space-between" my={2}>
        {ARTICLE_PROGRESS_BAR_STEPS.map((step) => (
          <Stack key={step}>
            <Stack direction="row" alignItems="center" spacing={1}>
              {isCompletedStep(step) ? (
                <DoneIcon
                  fontSize="medium"
                  sx={{
                    color: isCompletedStep(step) ?
                      theme.palette.primary.main :
                      theme.palette.grey[400],
                  }}
                />
              ) : (
                <DoubleArrowIcon
                  fontSize="medium"
                  sx={{
                    color: isInProgressStep(step) ?
                      theme.palette.primary.main :
                      theme.palette.grey[400],
                  }}
                />
              )}
              <Typography
                key={step}
                variant="body1"
                fontFamily={
                  isCompletedStep(step) || isInProgressStep(step) ?
                    "Open Sans Bold" :
                    "Open Sans Semi Bold"
                }
                color={
                  isCompletedStep(step) || isInProgressStep(step) ? "textPrimary" : "textSecondary"
                }
                align="center"
              >
                {toFriendlyProgressBarStep(step)}
              </Typography>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};
