import { createContext, ReactNode, useEffect, useState } from "react";
import { useAuth } from "../utils/client/useAuth";
import { IAIArticle } from "../types/models/article.types";
import { useGetUserArticles } from "../client/api/UserAPI";

interface UserArticlesContextData {
  articles: IAIArticle[];
  upsertArticle: (article: IAIArticle) => void;
  deleteArticle: (articleId: string) => void;
}

// Create context
export const UserArticlesContext = createContext<UserArticlesContextData | undefined>(undefined);

interface UserArticlesProviderProps {
  children: ReactNode;
}

// Provide context to components
export const UserArticlesProvider = ({ children }: UserArticlesProviderProps) => {
  const { userId } = useAuth();
  const { data } = useGetUserArticles(userId);
  const [articles, setArticles] = useState<IAIArticle[]>([]);

  useEffect(() => {
    if (data?.articles) {
      setArticles(data.articles);
    }
  }, [data?.articles]);

  const upsertArticle = (newArticle: IAIArticle) => {
    setArticles((prevArticles) => {
      const existingIndex = prevArticles?.findIndex((a) => a?.id === newArticle.id);

      // If article already exists, replace it
      if (existingIndex > -1) {
        const updatedArticles = [...prevArticles];
        updatedArticles[existingIndex] = newArticle;
        return updatedArticles;
      }

      // If article does not exist, add it
      return [newArticle, ...prevArticles];
    });
  };

  const deleteArticle = (articleId: string) => {
    setArticles((prevArticles) => prevArticles.filter((article) => article.id !== articleId));
  };

  return (
    <UserArticlesContext.Provider value={{ articles, upsertArticle, deleteArticle }}>
      {children}
    </UserArticlesContext.Provider>
  );
};
